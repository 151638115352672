<template>
    <section class="section team-area bg-grey ptb_100">
        <!-- Shape Top -->
        <div class="shape shape-top">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100" preserveAspectRatio="none" fill="#FFFFFF">
                <path class="shape-fill" d="M421.9,6.5c22.6-2.5,51.5,0.4,75.5,5.3c23.6,4.9,70.9,23.5,100.5,35.7c75.8,32.2,133.7,44.5,192.6,49.7
            c23.6,2.1,48.7,3.5,103.4-2.5c54.7-6,106.2-25.6,106.2-25.6V0H0v30.3c0,0,72,32.6,158.4,30.5c39.2-0.7,92.8-6.7,134-22.4
            c21.2-8.1,52.2-18.2,79.7-24.2C399.3,7.9,411.6,7.5,421.9,6.5z"></path>
            </svg>
        </div>
        <div class="container">
            <!-- <div class="row justify-content-center">
                <div class="col-12 col-md-10 col-lg-7">
                    <div class="section-heading text-center">
                        <h2>Core Team</h2>
                        <p class="d-none d-sm-block mt-4">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laborum obcaecati dignissimos quae quo ad iste ipsum officiis deleniti asperiores sit.</p>
                        <p class="d-block d-sm-none mt-4">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laborum obcaecati.</p>
                    </div>
                </div>
            </div> -->
            <div class="row justify-content-center">
                <div class="col-12 col-md-6 col-lg-4">
                    <!-- Single Team -->
                    <div class="single-team">
                        <!-- Team Photo -->
                        <div class="team-photo">
                            <a href="#"><img src="assets/img/rekha_kumari.jpg" alt="Rekha Kumari Vidyatra Managing Director and CEO" style="min-height:300px;max-height:300px;"></a>
                        </div>
                        <!-- Team Content -->
                        <div class="team-content p-3">
                            <a href="#">
                                <h3 class="mb-2">Rekha Kumari</h3>
                            </a>
                            <h5 class="text-uppercase">Co-Founder, MD</h5>
                            <h6 class="">director@vidyatra.com</h6>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-6 col-lg-4">
                    <!-- Single Team -->
                    <div class="single-team">
                        <!-- Team Photo -->
                        <div class="team-photo">
                            <a href="#"><img src="assets/img/arvind_kumar.jpg" alt="Arvind Kumar Vidyatra chief technical officer" style="min-height:300px;max-height:300px;"></a>
                        </div>
                        <!-- Team Content -->
                        <div class="team-content p-3">
                            <a href="#">
                                <h3 class="mb-2">Arvind Kumar (IIMA)</h3>
                            </a>
                            <h5 class="text-uppercase">Co-Founder, CEO</h5>
                            <h6 class="">ceo@vidyatra.com</h6>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-6 col-lg-4">
                    <!-- Single Team -->
                    <div class="single-team">
                        <!-- Team Photo -->
                        <div class="team-photo">
                            <a href="#"><img src="" class="fit" alt="Arjun Lather Vidyatra chief marketing officer" style="min-height:300px;max-height:300px;"></a>
                        </div>
                        <!-- Team Content -->
                        <div class="team-content p-3">
                            <a href="#">
                                <h3 class="mb-2">Arjun Lather</h3>
                            </a>
                            <h5 class="text-uppercase">CTO</h5>
                            <h6 class="">cto@vidyatra.com</h6>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-6 col-lg-6 row justify-content-center">
                    <!-- Single Team -->
                    <div class="single-team col-6">
                        <!-- Team Photo -->
                        <div class="team-photo">
                            <a href="#"><img src="assets/img/pankaj_yadav.jpg" alt="Pankaj Yadav Vidyatra chief marketing officer" style="min-height:180px;max-height:180px;min-width:200px;"></a>
                        </div>
                        <!-- Team Content -->
                        <div class="team-content p-3">
                            <a href="#">
                                <h3 class="mb-2">Pankaj Yadav</h3>
                            </a>
                            <h5 class="">(Operations Head)</h5>
                            <h6 class="">pankaj@vidyatra.com</h6>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-6 col-lg-6 row justify-content-center">
                    <div class="single-team col-6">
                        <!-- Team Photo -->
                        <div class="team-photo">
                            <a href="#"><img src="assets/img/layba-qureshi.jpeg" alt="Layba Qureshi Vidyatra" style="min-height:180px;max-height:200px;min-width:200px;"></a>
                        </div>
                        <!-- Team Content -->
                        <div class="team-content p-3">
                            <a href="#">
                                <h3 class="mb-2">Layba Qureshi</h3>
                            </a>
                            <h5 class="">(GM Sales & Support)</h5>
                            <h6 class="">layba@vidyatra.com</h6>
                        </div>
                    </div>
                </div>
                <!-- <div class="col-12 col-md-12 col-lg-12 row justify-content-center">
                    <div class="single-team">
                        <div class="team-photo">
                            <a href="#"><img src="assets/img/ajit-thakur.jpeg" alt="Ajit Singh Lakhimpur Kheri Vidyatra" style="min-height:200px;max-height:200px;"></a>
                        </div>
                        <div class="team-content p-3">
                            <a href="#">
                                <h3 class="mb-2">Ajit Thakur</h3>
                            </a>
                            <h5 class="text-uppercase">(AGM Sales)</h5>
                            <h6 class="">ajit.thakur@vidyatra.com</h6>
                        </div>
                    </div>
                </div> -->
                <!-- <div class="col-12 col-md-6 col-lg-12 row justify-content-center">
                    !-- Single Team --
                    <div class="single-team col-4">
                        !-- Team Photo --
                        <div class="team-photo">
                            <a href="#"><img src="assets/img/surya-sharma.jpg" alt="Surya Sharma Pilibhit Vidyatra" style="min-height:200px;max-height:200px;"></a>
                        </div>
                        !-- Team Content --
                        <div class="team-content p-3">
                            <a href="#">
                                <h3 class="mb-2">Surya Sharma</h3>
                            </a>
                            <h5 class="text-uppercase">Awadh Zone (Area Head)</h5>
                            <h6 class="">surya.sharma@vidyatra.com</h6>
                        </div>
                    </div>
                </div> -->
            </div>
        </div>
        <!-- Shape Bottom -->
        <div class="shape shape-bottom">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100" preserveAspectRatio="none" fill="#FFFFFF">
                <path class="shape-fill" d="M421.9,6.5c22.6-2.5,51.5,0.4,75.5,5.3c23.6,4.9,70.9,23.5,100.5,35.7c75.8,32.2,133.7,44.5,192.6,49.7
    c23.6,2.1,48.7,3.5,103.4-2.5c54.7-6,106.2-25.6,106.2-25.6V0H0v30.3c0,0,72,32.6,158.4,30.5c39.2-0.7,92.8-6.7,134-22.4
    c21.2-8.1,52.2-18.2,79.7-24.2C399.3,7.9,411.6,7.5,421.9,6.5z"></path>
            </svg>
        </div>
    </section>
</template>

<script>
export default {

}
</script>

<style>

</style>
